import {
  WhatIsMC,
  CalculateMC,
  TopMC,
  PriceCalculated,
  BaseAndQuote,
  Volume,
  CalculateChange,
  WhatIsCS,
  CirculatingSupply,
  TotalSupply,
  MaximumSupply,
} from './pages'

export const faqRootConfig = {
  description: 'Find answers to the most common questions regarding COIN360.',
  key: 'FAQ',
  path: '/faq/',
  text: 'FAQ',
  children: [
    {
      text: 'Cryptocurrency Market Capitalization',
      key: 'cryptocurrency-market-capitalization',
      children: [
        {
          text: 'What Is Market Capitalization?',
          key: 'what-is-market-capitalization',
          path: '/faq/cryptocurrency-market-capitalization/what-is-market-capitalization',
          external: false,
          viewComponent: WhatIsMC,
          breakAfter: false,
          header: 'Cryptocurrency Market Capitalization',
        },
        {
          text: 'How to Calculate Cryptocurrency Market Cap?',
          key: 'how-to-calculate-cryptocurrency-market-cap',
          path: '/faq/cryptocurrency-market-capitalization/how-to-calculate-cryptocurrency-market-cap',
          external: false,
          viewComponent: CalculateMC,
          breakAfter: false,
          header: 'Cryptocurrency Market Capitalization',
        },
        {
          text: 'What Is Total Crypto Market Cap?',
          key: 'what-is-total-crypto-market-cap',
          path: '/faq/cryptocurrency-market-capitalization/what-is-total-crypto-market-cap',
          external: false,
          viewComponent: TopMC,
          breakAfter: false,
          header: 'Cryptocurrency Market Capitalization',
        },
      ],
    },
    {
      text: 'Prices & Volumes',
      key: 'prices-and-volumes',
      children: [
        {
          text: 'How Is the Price of a Cryptocurrency Calculated?',
          key: 'how-is-the-price-of-a-cryptocurrency-calculated',
          path: '/faq/prices-and-volumes/how-is-the-price-of-a-cryptocurrency-calculated',
          external: false,
          viewComponent: PriceCalculated,
          breakAfter: false,
          header: 'Prices & Volumes',
        },
        {
          text: 'What Are Base and Quote Currencies?',
          key: 'what-are-base-and-quote-currencies',
          path: '/faq/prices-and-volumes/what-are-base-and-quote-currencies',
          external: false,
          viewComponent: BaseAndQuote,
          breakAfter: false,
          header: 'Prices & Volumes',
        },
        {
          text: 'What Is Volume in Cryptocurrency?',
          key: 'what-is-volume-in-cryptocurrency',
          path: '/faq/prices-and-volumes/what-is-volume-in-cryptocurrency',
          external: false,
          viewComponent: Volume,
          breakAfter: false,
          header: 'Prices & Volumes',
        },
        {
          text: 'How to Calculate Change?',
          key: 'how-to-calculate-change',
          path: '/faq/prices-and-volumes/how-to-calculate-change',
          external: false,
          viewComponent: CalculateChange,
          breakAfter: false,
          header: 'Prices & Volumes',
        },
      ],
    },
    {
      text: 'Cryptocurrency Supply',
      key: 'cryptocurrency-supply',
      children: [
        {
          text: 'What is Cryptocurrency Supply?',
          key: 'what-is-cryptocurrency-supply',
          path: '/faq/cryptocurrency-supply/what-is-cryptocurrency-supply',
          external: false,
          viewComponent: WhatIsCS,
          breakAfter: false,
          header:
            'Cryptocurrency Supply: the Difference between Circulating Supply, Total Supply and Maximum Supply',
        },
        {
          text: 'What Is Circulating Supply?',
          key: 'what-is-circulating-supply',
          path: '/faq/cryptocurrency-supply/what-is-circulating-supply',
          external: false,
          viewComponent: CirculatingSupply,
          breakAfter: false,
          header:
            'Cryptocurrency Supply: the Difference between Circulating Supply, Total Supply and Maximum Supply',
        },
        {
          text: 'What Is the Total Supply?',
          key: 'what-is-the-total-supply',
          path: '/faq/cryptocurrency-supply/what-is-the-total-supply',
          external: false,
          viewComponent: TotalSupply,
          breakAfter: false,
          header:
            'Cryptocurrency Supply: the Difference between Circulating Supply, Total Supply and Maximum Supply',
        },
        {
          text: 'What Is the Maximum Supply?',
          key: 'what-is-the-maximum-supply',
          path: '/faq/cryptocurrency-supply/what-is-the-maximum-supply',
          external: false,
          viewComponent: MaximumSupply,
          breakAfter: false,
          header:
            'Cryptocurrency Supply: the Difference between Circulating Supply, Total Supply and Maximum Supply',
        },
      ],
    },
  ],
}
