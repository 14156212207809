import styles from './styles.module.scss'
import { stringToHtml } from 'src/i18n/formatHtml'
import { intl } from 'src/i18n'

export const TotalSupply = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.totalSupply.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.totalSupply.text1')}</p>
          <p>{intl.t('faq.pages.totalSupply.text2')}</p>
          <p>{intl.t('faq.pages.totalSupply.example')}</p>
          <p
            className={styles.note}
            dangerouslySetInnerHTML={stringToHtml(
              intl.t('faq.pages.totalSupply.text3')
            )}
          />
          <p className={styles.fact}>{intl.t('faq.pages.totalSupply.text4')}</p>
        </section>
      </div>
    </>
  )
}

export default TotalSupply
