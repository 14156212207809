import FaqRootLinks from '../FaqRootLinks'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const FaqRoot = () => {
  return (
    <div className={styles.main}>
      <div className={styles.headercontainer}>
        <h2 className={styles.header}>
          {intl.t('faq.components.faqMain.header')}
        </h2>
        <div className={styles.description}>
          {intl.t('faq.components.faqMain.description')}
        </div>
      </div>
      <FaqRootLinks />
    </div>
  )
}

export default FaqRoot
