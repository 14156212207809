import { wrapper } from 'src/redux/store'
import {
  getLineNews,
  getRemoteMapData,
  populateFilterStore,
} from 'src/redux/thunks'
import { ENTITY } from 'src/consts'
import Main from 'src/pages/Main'

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ query, res }) => {
      await store.dispatch(populateFilterStore(query, ENTITY.COIN))
      await store.dispatch(getLineNews(process.env.NEXT_PUBLIC_API_ENDPOINT))
      await store.dispatch(
        getRemoteMapData({
          entity: ENTITY.EXCHANGE,
          url: process.env.NEXT_PUBLIC_API_ENDPOINT,
        })
      )

      res.setHeader('Cache-Control', 'public, s-maxage=60')
    }
)

export default Main
