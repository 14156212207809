import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'next/image'
import { zoomIn, zoomOut } from 'src/redux/actions'
import ShareButton from 'src/components/ShareButton'
// import NewsModal from '../NewsModal'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const FooterMobile = () => {
  const dispatch = useDispatch()
  // const [modalOpen, setModalOpen] = useState(false)

  const handleZoomIn = () => {
    dispatch(zoomIn())
  }

  const handleZoomOut = () => {
    dispatch(zoomOut())
  }

  // const toggleModal = () => {
  //   setModalOpen(!modalOpen)
  // }

  return (
    <div className={styles.footer}>
      <div className={styles.zoom}>
        <button className={styles.btn} onClick={handleZoomOut}>
          <Image
            src='/assets/icons/icon-zoom-out.svg'
            width='20px'
            height='4px'
            alt='zoom out'
          />
        </button>
        <button className={styles.btn} onClick={handleZoomIn}>
          <Image
            src='/assets/icons/icon-zoom-in.svg'
            width='20px'
            height='20px'
            alt='zoom in'
          />
        </button>
      </div>
      {/* {modalOpen && <NewsModal onClose={toggleModal} />}{' '}
      <button className={styles.newsButton} onClick={toggleModal}>
        Live News
        <Image
          className={styles.closeIcon}
          src='/assets/icons/icon-arrow-up-new.svg'
          width='15px'
          height='15px'
          alt='Open arrow'
        />
      </button>{' '} */}
      <ShareButton className={styles.share}>
        {intl.t('components.footerMobile.share')}
      </ShareButton>
    </div>
  )
}

export default FooterMobile
