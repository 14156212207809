import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'
import { stringToHtml } from 'src/i18n/formatHtml'
import { intl } from 'src/i18n'

export const WhatIsCS = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.whatIsCS.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.whatIsCS.text1')}</p>
          <p>{intl.t('faq.pages.whatIsCS.text2')}</p>
          <p>{intl.t('faq.pages.whatIsCS.example')}</p>
          <p
            className={styles.note}
            dangerouslySetInnerHTML={stringToHtml(
              intl.t('faq.pages.whatIsCS.text3')
            )}
          />

          <Link href='/coin/bitcoin-btc' passHref>
            <a rel='noopener nofollow noreferrer' target='_blank'>
              <Image
                src='/assets/images/faq/supply-diff-between.png'
                width='770px'
                height='383px'
                alt='Example: Bitcoin is issued in a fixed maximum supply of 21,000,000 BTC'
              />
            </a>
          </Link>

          <p>{intl.t('faq.pages.whatIsCS.text4')}</p>
          <p>{intl.t('faq.pages.whatIsCS.example')}</p>
          <p className={styles.note}>{intl.t('faq.pages.whatIsCS.text5')}</p>

          <Link href='/coin/ethereum-eth' passHref>
            <a rel='noopener nofollow noreferrer' target='_blank'>
              <Image
                src='/assets/images/faq/supply-totals.png'
                width='770px'
                height='385px'
                alt='Example: ETH does not have any fixed maximum supply and can be produced endlessly'
              />
            </a>
          </Link>

          <p>{intl.t('faq.pages.whatIsCS.text6')}</p>
        </section>
      </div>
    </>
  )
}

export default WhatIsCS
