import Image from 'next/image'
import useWidth from 'src/hooks/useWidth'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'
import { stringToHtml } from 'src/i18n/formatHtml'

export const BaseAndQuote = ({ header }) => {
  const { isMobile } = useWidth()

  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.baseAndQuote.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.baseAndQuote.text1')}</p>
          <p>{intl.t('faq.pages.baseAndQuote.example')}</p>
          <p
            className={styles.note}
            dangerouslySetInnerHTML={stringToHtml(
              intl.t('faq.pages.baseAndQuote.text2')
            )}
          />
          {isMobile ? (
            <Image
              src='/assets/images/faq/base-quote-currencies-mobile.png'
              width='770px'
              height='260px'
              alt='Base & Quote Currencies on Coin360 map'
            />
          ) : (
            <Image
              src='/assets/images/faq/base-quote-currencies.png'
              width='770px'
              height='260px'
              alt='Base & Quote Currencies on Coin360 map'
            />
          )}
        </section>
      </div>
    </>
  )
}

export default BaseAndQuote
