import styles from './styles.module.scss'
import { stringToHtml } from 'src/i18n/formatHtml'
import { intl } from 'src/i18n'

export const MaximumSupply = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.maximumSupply.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.maximumSupply.text1')}</p>
          <p>{intl.t('faq.pages.maximumSupply.example')}</p>
          <p
            className={styles.note}
            dangerouslySetInnerHTML={stringToHtml(
              intl.t('faq.pages.maximumSupply.text2')
            )}
          />

          <p className={styles.fact}>
            {intl.t('faq.pages.maximumSupply.text3')}
          </p>
        </section>
      </div>
    </>
  )
}

export default MaximumSupply
