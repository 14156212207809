import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Image from 'next/image'
import { NewsFeedWidget } from './Widgets/NewsFeed'
import Link from 'next/link'
import useWidth from 'src/hooks/useWidth'
import { CryptopanicWidget } from './Widgets/Cryptopanic'
import { StatisticsWidget } from './Widgets/Statistics'
import { FooterNavbar } from '../FooterNavbar'

// Popup Component
const SignUpPopup = ({ onClose }) => {
  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.popup}>
        <Image
          className={styles.closeIcon}
          src='./assets/icons/icon-close.svg'
          width={25}
          height={25}
          alt='close'
          onClick={onClose} // Add this line to ensure the icon can close the popup
        />
        <div className={styles.header}>Subscribe to our weekly newsletter</div>
        <iframe
          src='https://cdn.forms-content.sg-form.com/98388cb6-dd00-11ed-bba7-b2a480f00fab'
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </div>
    </div>
  )
}

export const RightSidebar = ({ setIsSidebarOpened }) => {
  const { isMobile } = useWidth()
  const [rightSidebar, setRightSidebar] = useState(!isMobile)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setRightSidebar(false)
      setIsSidebarOpened(false)
    }
  }, [isMobile])

  return (
    <>
      <div
        className={clsx(styles.rightSidebarButtom, {
          [styles.rightSidebarButtomClosed]: !rightSidebar,
        })}
      >
        <Image
          src={`/assets/icons/icon-right-sidebar-${
            rightSidebar ? 'closed' : 'open'
          }.svg`}
          width='20px'
          height='20px'
          alt='sidebar icon'
          onClick={() => {
            setRightSidebar(!rightSidebar)
            setIsSidebarOpened(!rightSidebar)
          }}
        />
      </div>
      <div
        className={clsx(styles.rightSidebar, {
          [styles.rightSidebarOpen]: rightSidebar,
        })}
      >
        {rightSidebar && (
          <>
            <div className={styles.widgetsContainer}>
              <NewsFeedWidget />
              <div className={styles.signUp}>
                <div className={styles.signUpWrapper}>
                  <div
                    className={styles.signUpText}
                    onClick={() => setIsPopupOpen(true)}
                  >
                    <Image
                      src={`/assets/icons/icon-newsletter-black.svg`}
                      width='22px'
                      height='22px'
                      alt='newsletter icon'
                    />
                    Sign up for exciting news weekly!
                  </div>
                </div>
              </div>
              {/* <CryptopanicWidget /> */}
              <StatisticsWidget />
            </div>
            <FooterNavbar />
          </>
        )}
      </div>
      {isPopupOpen && <SignUpPopup onClose={() => setIsPopupOpen(false)} />}
    </>
  )
}
