import { useSelector } from 'react-redux'
import { getCurrentCurrencySelector } from 'src/redux/selectors'
import styles from './styles.module.scss'
import { MainWidget } from '../MainWidget'
import { formatValueWithCurrencyOB } from 'src/utils/currency'
import clsx from 'clsx'
import { useGlobalStats } from 'src/state/globalStats'
import { getDirectionByChange } from 'src/utils/common'
import { formatChangeValue } from 'src/utils/number/number'

export const StatisticsWidget = () => {
  const { data } = useGlobalStats()
  const currency = useSelector(getCurrentCurrencySelector)

  const volumeDirection = getDirectionByChange(data?.volumeChange)
  const mCapDirection = getDirectionByChange(data?.marketCapChange)

  return (
    <MainWidget title={'Statistics'} classNameOpen={styles.classNameOpen}>
      <div className={styles.coinsExchanges}>
        <div className={styles.itemCoinsExchanges}>
          <span className={styles.titleItem}>Coins</span>
          <span className={styles.data}>{data?.coins ? data?.coins : '?'}</span>
        </div>
        <div className={styles.itemCoinsExchanges}>
          <span className={styles.titleItem}>Exchanges</span>
          <span className={styles.data}>
            {data?.exchanges ? data?.exchanges : '?'}
          </span>
        </div>
      </div>
      <section className={styles.columnItems}>
        <div className={styles.item}>
          <span className={styles.titleItem}>Market Cap</span>
          <span className={styles.data}>
            {data?.marketCap
              ? formatValueWithCurrencyOB(data?.marketCap, currency, true, true)
              : '?'}
            <span className={clsx(styles.change, styles[mCapDirection])}>
              {data?.marketCapChange ? `${data?.marketCapChange}%` : '?'}
            </span>
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.titleItem}>24h Vol</span>
          <span className={styles.data}>
            {data?.volume
              ? formatValueWithCurrencyOB(data?.volume, currency, true, true)
              : '?'}
            <span className={clsx(styles.change, styles[volumeDirection])}>
              {data?.volumeChange ? `${data?.volumeChange}%` : '?'}
            </span>
          </span>
        </div>
        <div className={styles.item}>
          <span className={styles.titleItem}>Dominance</span>
          <span className={clsx(styles.data, styles.dominance)}>
            <div>
              {data?.dominanceBTC
                ? `${formatChangeValue(data.dominanceBTC, false)}% `
                : '? '}
              <span className={styles.titleItem}>BTC</span>
            </div>
            <div>
              {data?.dominanceETH
                ? `${formatChangeValue(data.dominanceETH, false)}% `
                : '? '}
              <span className={styles.titleItem}>ETH</span>
            </div>
          </span>
        </div>
      </section>
    </MainWidget>
  )
}
