import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'
import { intl } from 'src/i18n'

export const PriceCalculated = ({ header }) => {
  return (
    <>
      <h1 className={styles.header}>{header}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          {intl.t('faq.pages.priceCalculated.header')}
        </div>
        <section>
          <p>{intl.t('faq.pages.priceCalculated.text1')}</p>
          <p>
            {intl.t('faq.pages.priceCalculated.text2')}
            <Link href='/about/methodology'>
              <a
                className={styles.link}
                rel='noopener noreferer'
                target='_blank'
              >
                {intl.t('faq.pages.priceCalculated.link')}
              </a>
            </Link>
          </p>
          <Image
            src='/assets/images/faq/calculate-price.png'
            width='770px'
            height='515px'
            alt='How coin360 calculate cryptocurrency price & volume'
          />

          <p className={styles.fact}>
            {intl.t('faq.pages.priceCalculated.text3')}
          </p>
        </section>
      </div>
    </>
  )
}

export default PriceCalculated
